import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, navigate } from 'gatsby'

import SubmitButton from './SubmitButton'
import { RegionContainer } from '../styles/Utility.styles'
import * as Styled from './styles/LoginForm.styles'
import {
  AuthForm,
  FormContainer,
  FormHeader,
  FieldGroup,
} from './styles/AuthFormCommon.styles'
import LabeledTextField from './LabeledTextField'
import { VALIDATION_RULES } from './validationRules'
import { useAuth } from './useAuth'

const QuestionRow = ({ question, cta, link }) => (
  <Styled.CtaRow>
    <Styled.QuestionPrompt>
      {question}
      <Link to={link}>{cta}</Link>
    </Styled.QuestionPrompt>
  </Styled.CtaRow>
)

// TODO: If logged in, just redirect to Edit page, or show a link to it here
const LoginForm = ({ isAdminLoginForm = false }) => {
  const auth = useAuth()
  const [isPasswordChallenge, setIsPasswordChallenge] = useState(false)
  const [passwordIsReset, setPasswordIsReset] = useState(false)
  const { handleSubmit, register, setError, errors, setValue } = useForm()

  const onSubmit = values => {
    auth.signIn(values.email, values.password).then(result => {
      // console.dir(result)
      if (result.attributes) {
        if (!isAdminLoginForm) {
          navigate('/health-care-provider-directory/edit-registration')
        } else {
          navigate('/admin/hcpd-admin')
        }
      } else if (isPasswordChallenge) {
        auth.completeNewPassword(values.email, values.newpassword).then(() => {
          setIsPasswordChallenge(false)
          setPasswordIsReset(true)
          setValue('password', null)
        })
      } else if (result.challengeName) {
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsPasswordChallenge(true)
        }
      } else if (result.code === 'NotAuthorizedException') {
        setError('password', {
          type: 'manual',
          shouldFocus: true,
          message: 'Incorrect password. Please try again or reset.',
        })
      } else if (result.code === 'UserNotFoundException') {
        setError('email', {
          type: 'manual',
          shouldFocus: true,
          message: 'No user exists for this email address.',
        })
      } else {
        console.log(result)
      }
    })
  }

  return (
    <RegionContainer>
      <FormContainer>
        <FormHeader>Login</FormHeader>

        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup>
            <LabeledTextField
              label="Email"
              type="text"
              name="email"
              isRequired
              fieldWidth="47%"
              errors={errors.email}
              fieldRef={register(VALIDATION_RULES.email)}
            />
            <LabeledTextField
              label={!passwordIsReset ? 'Password' : 'Confirm updated password'}
              type="password"
              name="password"
              isRequired
              fieldWidth="47%"
              errors={errors.password}
              fieldRef={register(VALIDATION_RULES.password)}
            />
          </FieldGroup>

          {isPasswordChallenge && (
            <FieldGroup>
              <LabeledTextField
                label="First Time Login: Password Reset Required"
                type="password"
                name="newpassword"
                isRequired
                fieldWidth="100%"
                errors={errors.newpassword}
                fieldRef={register(VALIDATION_RULES.password)}
              />
            </FieldGroup>
          )}

          {!isAdminLoginForm && (
            <>
              {/* TODO: Add this Reset back in */}
              <QuestionRow
                question="Forgot Your Password?"
                cta="Reset Password"
                link="/health-care-provider-directory/reset-password"
              />
              <QuestionRow
                question="Having problems logging in?"
                cta="Contact Us"
                link="/contact-us"
              />
            </>
          )}
          <SubmitButton type="submit" text="Log In" />
        </AuthForm>
      </FormContainer>
    </RegionContainer>
  )
}

export default LoginForm
