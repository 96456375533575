import React from 'react'

import SEO from '../SEO'
import Layout from '../Layout'
import HcpdPageHero from './HcpdPageHero'

const HcpdFormPageHoc = ({ doc, children }) => {
  if (!doc) {
    return null
  }
  const twitterImage = doc.twitter_image ? doc.twitter_image : null
  const facebookImage = doc.facebook_image ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc?.meta_description ?? '',
          keywords: doc?.meta_keywords ?? '',
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <HcpdPageHero
          title={doc.title?.text ?? 'Health Care Provider Login'}
          body={doc.body_text}
        />
        {children}
      </Layout>
    </>
  )
}

export default HcpdFormPageHoc
