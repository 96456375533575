import styled from 'styled-components'

export const CtaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const QuestionPrompt = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);

  a {
    color: var(--content-background-dark-blue);
    padding-left: 7px;

    :hover {
      color: var(--highlight-blue);
    }
  }
`
