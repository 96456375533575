import React from 'react'
import { graphql } from 'gatsby'

import LoginForm from '../../components/Auth/LoginForm'
import HcpdFormPageHoc from '../../components/HealthCareProviderDirectory/HcpdFormPageHoc'
import SliceRenderer from '../../components/ContentBlocks/SliceRenderer'
import { ProvideAuth } from '../../components/Auth/useAuth'

export const query = graphql`
  {
    prismicHcpdLoginPage {
      data {
        title {
          text
        }
        twitter_image {
          url
        }
        meta_title {
          text
        }
        meta_keywords
        meta_description
        facebook_image {
          url
        }
        display_name
        body_text {
          text
          richText
        }
        body {
          ... on PrismicHcpdLoginPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              header {
                text
                richText
              }
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              body_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`

const HcpdLoginPage = ({ data }) => {
  const doc = data.prismicHcpdLoginPage.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <HcpdFormPageHoc doc={doc}>
        <LoginForm />
        {doc.body &&
          doc.body.map(block => <SliceRenderer key={block.id} block={block} />)}
      </HcpdFormPageHoc>
    </ProvideAuth>
  )
}

export default HcpdLoginPage
